import React from 'react';
import HomePageHero from './HomeHero';
import WhoWeAre from './WhoWeAre';
// import ContactUs from './ContactUs';
import OurBrand from './OurBrand';
export default function HomePage(props) {

    return (
        <div>
            <HomePageHero />
            <WhoWeAre />
            <OurBrand />
            {/* <ContactUs /> */}
        </div>
    );
}