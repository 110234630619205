import React from 'react';
import { Grid, Typography, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    homeHero: {
        backgroundColor: '#EAECED',
        paddingTop: theme.spacing(15),
        paddingBottom: theme.spacing(10),
        [theme.breakpoints.down("sm")]: {
            paddingBottom: theme.spacing(9),
        },
        [theme.breakpoints.down("xs")]: {
            paddingBottom: theme.spacing(5),
        }
    },

    homeHeroTitle: {
        fontWeight: 500,
        color: '#333',
        fontFamily: 'PPWoodland',
    },
    underline: {
        position: 'relative',
        zIndex: 9,
        '&::after': {
            display: 'block',
            content: '""',
            width: '80%',
            height: 40,
            background: '#5BE29E',
            position: 'absolute',
            bottom: '-8px',
            right: 0,
            zIndex: '-1',
            borderRadius: 8,
            [theme.breakpoints.down("sm")]: {
                height: 32,
            },
            [theme.breakpoints.down("xs")]: {
                height: 24,
                bottom: '-12px',
            }
        }
    }
}));

export default function HomeHero(props) {

    const classes = useStyles();

    return (
        <div id="HOME" className={classes.homeHero}>
            <Container >
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={12} >
                        <Typography variant="h1" className={classes.homeHeroTitle}><span className={classes.underline}>Brands</span> that make the world <span className={classes.underline}>healthier &</span> <span className={classes.underline}>happier</span></Typography>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}
