import React from 'react';
import { Grid, Typography, Container, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Feature from './../../assets/images/GeodystFlower.png';

const useStyles = makeStyles((theme) => ({
    whoWeAre: {
        backgroundColor: '#EAECED',
    },
    whoWeAreFeature: {
        width: '100%',
        display: 'block',
    },
    whoWeAreTitle: {
        fontWeight: 500,
        color: '#333',
        fontFamily: 'PPWoodland'
    },
    whoWeAreSectionSubtitle: {
        fontWeight: 400,
        color: '#707070',
        fontFamily: 'Quicksand',
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(4),
        [theme.breakpoints.down("sm")]: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(3),
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(2),
        }
    },
}));

export default function WhoWeAre(props) {

    const classes = useStyles();

    return (
        <div id="HOME" className={classes.whoWeAre}>
            <Container >
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={12} sm={6} md={6}>
                        <img src={Feature} className={classes.whoWeAreFeature} alt=""></img>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Typography variant="h2" className={classes.whoWeAreTitle}>Who we are</Typography>
                        <Typography variant="h5" className={classes.whoWeAreSectionSubtitle}>We scour the globe for the hidden gems of health and wellness, with a particular focus on nutraceuticals and vitamins. We are also creating our own label ranges too!

                            <br />
                            <br />
                            We have years of experience in the industry right across the supply chain. And we are using this experience to disrupt the market for the better by providing a range of authentic health and wellness products tailored to customers’ needs.
                        </Typography>
                        <Button variant="contained" color="primary" size='large' disableElevation href="mailto:stuart@geodyst.com" rel=''>CONTACT US</Button>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}
