import React from 'react';
import { Grid, Typography, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import Feature from './../../assets/images/GeodystFlower.png';
import Gem from './../../assets/images/brand/gem.png';
import Arche from './../../assets/images/brand/arche.png';
import Allok from './../../assets/images/brand/allok.png';
// import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    ourBrand: {
        // position: 'relative',
        backgroundColor: '#EAECED',
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(10),
        [theme.breakpoints.down("sm")]: {
            paddingTop: theme.spacing(9),
            paddingBottom: theme.spacing(9),
        },
        [theme.breakpoints.down("xs")]: {
            paddingTop: theme.spacing(8),
            paddingBottom: theme.spacing(8),
        }
    },
    whoWeAreBrandTitle: {
        fontWeight: 500,
        fontFamily: 'PPWoodland',
        color: '#707070',
        textAlign: 'center',
        marginBottom: theme.spacing(5),
        [theme.breakpoints.down("sm")]: {
            marginBottom: theme.spacing(4),
        },
        [theme.breakpoints.down("xs")]: {
            marginBottom: theme.spacing(3),
        }
    },
    brandLogo: {
        width: '100%',
        display: 'block',
        maxWidth: '100px',
        margin: '0 auto',
    },
}));

export default function WhoWeAre(props) {

    const classes = useStyles();

    return (
        <div id="ourBrand" className={classes.ourBrand}>
            <Container >
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                        <Typography variant="h3" component="h3" className={classes.whoWeAreBrandTitle}>Our Brands</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={4} justifyContent="center" alignItems="center">
                    <Grid item xs={12} sm={4}>
                        <img src={Allok} className={classes.brandLogo} alt=""></img>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <img src={Arche} className={classes.brandLogo} alt=""></img>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <img src={Gem} className={classes.brandLogo} alt=""></img>
                    </Grid>
                </Grid>
            </Container >
        </div >
    );
}
