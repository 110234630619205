import React, {useState} from 'react';
import { Grid, Typography, Container, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl } from '@material-ui/core';
import { FormLabel } from '@material-ui/core';
// import Snackbar from '@material-ui/core/Snackbar';
// import { MuiAlert } from '@material-ui/lab/Alert';
import { send } from 'emailjs-com';


// function Alert(props) {
//     return <MuiAlert elevation={6} variant="filled" {...props} />;
// }

const useStyles = makeStyles((theme) => ({
    contactUs: {
        backgroundColor: '#EAECED',
        paddingTop: theme.spacing(15),
        paddingBottom: theme.spacing(10),
        [theme.breakpoints.down("sm")]: {
            paddingTop: theme.spacing(13),
            paddingBottom: theme.spacing(9)
        },
        [theme.breakpoints.down("xs")]: {
            paddingTop: theme.spacing(10),
            paddingBottom: theme.spacing(8)
        }
    },

    contactUsTitle: {
        fontWeight: 500,
        color: '#333',
        fontSize: 72,
        fontFamily: 'PPWoodland',
        marginBottom: theme.spacing(10),
        [theme.breakpoints.down("sm")]: {
            fontSize: 52,
            marginBottom: theme.spacing(9),
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: 36,
            marginBottom: theme.spacing(8),
        }
    },
    contactUsSubtitle: {
        fontWeight: 500,
        color: '#707070',
        fontFamily: 'PPWoodland',
        textTransform: 'capitalize',
        marginBottom: theme.spacing(2.5),
        [theme.breakpoints.down("sm")]: {
            marginBottom: theme.spacing(2),
        },
        [theme.breakpoints.down("xs")]: {
            marginBottom: theme.spacing(1.5),
        }
    },
    contactUsBody: {
        fontWeight: 400,
        color: '#333333',
        fontFamily: 'Quicksand',
    },
}));

export default function ContactUs(props) {

    const classes = useStyles();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [sendMsg, setSend] = useState(false)
    // const [open, setOpen] = React.useState(false);
    const sendEmail = () => {
        setSend(true);
        if (name && email && message) {
            send('service_wzte5qg', 'geodyst_contact_us', { name, email, message }, 'NsCVWjSWmBGmuPC7D')
                .then((result) => {
                    // setOpen(true);
                    setSend(false);
                    setName('');
                    setEmail('');
                    setMessage('');
                }).catch((error) => {
                    console.log(error.text);
                });
                console.log('ssdsds')
        }
    }

    // const handleClose = (event, reason) => {
    //     if (reason === 'clickaway') {
    //         return;
    //     }

    //     // setOpen(false);
    // };
    
    return (
        <div id="CONTACT" className={classes.contactUs}>
            <Container >
                <Grid container  >
                    <Grid item xs={12} >
                        <Typography variant='h1' className={classes.contactUsTitle}>Contact</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h3" className={classes.contactUsSubtitle}>Address</Typography>
                        <Typography variant="h5" className={classes.contactUsBody}>Geodyst Limited<br />
                            Court House<br />
                            Windmill Way<br />
                            Stamford, Lincolnshire PE9 2RH<br />
                            UK</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h3" className={classes.contactUsSubtitle}>Get In Touch</Typography>
                        <Typography variant="h5" className={classes.contactUsBody}>
                            stuart@geodyst.com
                            <br /> +44 7970 250300</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <Typography variant="h3" component="h1" className={classes.contactUsSubtitle}>Send Us A Message</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl>
                                    <FormLabel>
                                        NAME(required)
                                    </FormLabel>
                                </FormControl>
                                <TextField value={name} required onChange={(e) => setName(e.target.value)} color='secondary' variant="outlined" fullWidth margin="dense" />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl>
                                    <FormLabel>
                                        EMAIL(required)
                                    </FormLabel>
                                </FormControl>
                                <TextField required value={email} onChange={(e) => setEmail(e.target.value)} color='secondary' variant="outlined" fullWidth margin="dense" />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl>
                                    <FormLabel>
                                        MESSAGE(required)
                                    </FormLabel>
                                </FormControl>
                                <TextField value={message} required onChange={(e) => setMessage(e.target.value)} multiline rows={4} color='secondary' variant="outlined" fullWidth margin="dense" />
                            </Grid>
                            <Grid item xs={8} sm={6} md={4}>
                            {!sendMsg ? <Button disabled={!name || !message || !message} onClick={sendEmail} variant="contained" color="primary" size="large" fullWidth > SUBMIT</Button> :
                                    <Typography variant="h2" className={classes.emailWrapperTitle}>SENDING</Typography>}
                                {/* <Button variant="contained" color="primary" size='large' fullWidth disableElevation>SUBMIT</Button> */}
                            </Grid>
                            {/* <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                                EMAIL SENT
                                <Alert onClose={handleClose} severity="success">
                                    EMAIL SENT
                                </Alert>
                            </Snackbar> */}
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}
