import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Container, Link } from "@material-ui/core";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import HomePage from "./pages/homePage/Index";
import { makeStyles } from "@material-ui/core/styles";
// import Button from "@material-ui/core/Button";
// import Drawer from "@material-ui/core/Drawer";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import { IconButton } from "@material-ui/core";
import Footer from './Footer.js';
import Geodyst from './assets/images/geodystHeaderLogo.png';
import { Routes, Route } from "react-router-dom";
import ContactUs from "./pages/homePage/ContactUs";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "transparent !important",
    // paddingLeft: "10%",
    // paddingRight: "10%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "8%",
      paddingRight: "8%",
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    "& button": {
      color: "#fff",
    },
    "&.MuiPaper-elevation4": {
      backgroundColor: "#fff !important",
      "& button": {
        color: "#000",
      },
    },
  },
  lunaHeaderLogo: {
    width: 80,
    display: "block",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  mainBody: {
    marginTop: "-64px",
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(-3),
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  headerNav: {
    display: "block",
    '& a':{
      textDecoration:'none',
      cursor:'pointer',
      color:'#333333',
    }
    // [theme.breakpoints.down("xs")]: {
    //   display: "none",
    // },
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    width: 200,
    "& .MuiListItem-root": {
      marginBottom: theme.spacing(2),
    },
  },
  closeMenuButton: {
    width: "fit-content",
  },
}));

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
    backgroundColor: "#fff",
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export const HEADER_NAV = ["ABOUT", "TEAM", "PORTFOLIO", "CONTACT"];

export default function ElevateAppBar(props) {
  const classes = useStyles();
  // const [mobileOpen, setMobileOpen] = React.useState(false);

  // function handleDrawerToggle() {
  //   setMobileOpen(!mobileOpen);
  // }

  // const handleNavigation = (id) => {
  //   const anchor = document.querySelector(`#${id}`);
  //   anchor.scrollIntoView({ behavior: "smooth", block: "start" });
  //   console.log('run')
  // };
  // const drawer = (
  //   <List>
  //     {HEADER_NAV?.map((item) => (
  //       <ListItem onClick={() => handleNavigation(item)} button>
  //         {item}
  //       </ListItem>
  //     ))}
  //   </List>
  // );

  return (
    <React.Fragment>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar position="fixed" className={classes.appBar}>
          <Container>
            <Toolbar disableGutters>
              <Typography variant="h6" className={classes.title}>
                <a href="/">
                  <img
                    src={Geodyst}
                    alt="text"
                    className={classes.lunaHeaderLogo}
                  />
                </a>
              </Typography>
              <div className={classes.headerNav}>
                <Link href="/Contact">
                  <Typography variant="subtitle1">
                    CONTACT
                  </Typography>
                </Link>
              </div>
              {/* <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                onClick={handleDrawerToggle}
              >
              </IconButton> */}

              {/* <Drawer
                variant="temporary"
                anchor={"right"}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                <IconButton
                  onClick={handleDrawerToggle}
                  className={classes.closeMenuButton}
                >
                </IconButton>
                {drawer}
              </Drawer> */}
            </Toolbar>
          </Container>
        </AppBar>
      </ElevationScroll>
      <Toolbar />
      <div className={classes.mainBody}>
      <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path="Home" element={<HomePage />} />
          <Route path="Contact" element={<ContactUs />} />
        </Routes>
      </div>
      <Footer />
    </React.Fragment>
  );
}

