import React from 'react';
import { Grid, Typography, Divider, Container, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Geodyst from './assets/images/geodystLogo.png';


const useStyles = makeStyles((theme) => ({
    footer: {
        backgroundColor: '#333333',
    },
    footerWrapper: {
        padding: theme.spacing(5, 0),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(4, 0),
        },
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(3, 0),
        }
    },
    footerLogo: {
        width: '100%',
        display: 'block',
        objectFit: 'contain',
        maxWidth: '100px',
    },
    footerDivider: {
        backgroundColor: '#707070',
        height: '0.8px',
        width: '100%'
    },
    footerBottomWrapper: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down("xs")]: {
            flexDirection: 'column',
        },
        '& a':{
            marginRight:16,
            textDecoration:'none',
            cursor:'pointer',
        },
        '& a:last-child':{
            marginRight:0,
        }
    },
    footerPrivacyText: {
        color: '#D6DADD',
        fontWeight: '500',
    },
}));

export default function Footer(props) {
    const classes = useStyles();


    return (
        <div className={classes.footer}>
            <Container className={classes.footerWrapper}>
                <Grid container>
                    <Grid item xs={12}>
                        <img src={Geodyst} className={classes.footerLogo} alt=""></img>
                    </Grid>
                </Grid>
            </Container>
            <Divider className={classes.footerDivider}></Divider>
            <Container className={classes.footerWrapper}>
                <Grid container alignItems='center' justifyContent='space-between'>
                    <Grid item xs={12} sm>
                        <Typography variant="subtitle1" className={classes.footerPrivacyText}>@2022 GEODYST</Typography>
                    </Grid>
                    <Grid item xs={12} sm="auto">
                        <div className={classes.footerBottomWrapper}>
                            <Link href="https://app.termly.io/document/privacy-policy/264ee660-7b5f-4264-b536-5ff08194b1f4" target='_blank' re>
                                <Typography variant="subtitle1" className={classes.footerPrivacyText}>PRIVACY POLICY</Typography>
                            </Link>
                            <Link href="https://app.termly.io/document/disclaimer/55738482-2456-4498-9412-bdcfff4338d2" target='_blank'>
                                <Typography variant="subtitle1" className={classes.footerPrivacyText}>DISCLAIMER</Typography>
                            </Link>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}
